export const acme = {
  global: {
    breakpoints: {
      xsmall: {
        value: 500,
      },
      small: {
        value: 900,
      },
      medium: undefined,
      middle: {
        value: 3000,
      },
    },
    font: {
      family: "Quicksand",
      size: "18px",
      height: "20px",
    },
    colors: {
      /* BEGIN: Color Palette Definition */
      teal: {
        dark: "#655D8A",
        light: "#24A19C",
      },
      "teal!": "#24A19C",
      "light-bg": "#FFFFFF",
      "dark-bg": "#1B1A17",
      "light-text": "#151D3B",
      "dark-text": "#ECECEC",
      /* END: Color Palette Definition */
      /* BEGIN: Mapping Colors to Grommet Namespaces */
      background: {
        dark: "dark-bg",
        light: "light-bg",
      },
      brand: "teal!",
      control: {
        dark: "brand",
        light: "brand",
      },
      input: {
        background: "blue",
      },
      text: {
        dark: "dark-text",
        light: "light-text",
      },
    },
    focus: {
      border: {
        color: "gold",
      },
    },
    /* END: Mapping Colors to Grommet Namespaces */
  },
  /* BEGIN: Mapping Colors to Components */
  anchor: {
    color: {
      dark: "gold",
      light: "amethyst!",
    },
  },
  /* END: Mapping Colors to Components */
};
