import React, { useState } from "react";
import {
  Accordion,
  AccordionPanel,
  Anchor,
  Avatar,
  Box,
  Button,
  Heading,
  Image,
  Grid,
  Grommet,
  Page,
  PageContent,
  Paragraph,
  Text,
  Tip,
  ResponsiveContext,
} from "grommet";

import { deepMerge } from "grommet/utils";
import { acme } from "./acme-theme";
import { grommet } from "grommet";

import { Sun, Moon } from "grommet-icons";
import rumiavatarhello from "./images/rumi-avatar-hello.png";
import rumiavatarheart from "./images/rumi-avatar-heart.png";
import rumiavatarcontact from "./images/rumi-avatar-contact.png";
import bitsnbytes from "./images/bitsnbytes-cloud.png";
import emaildark from "./images/email-dark.png";
import emaillight from "./images/email-light.png";
import phonedark from "./images/phone-dark.png";
import phonelight from "./images/phone-light.png";
import rumiavatarheader from "./images/rumi-profile-art.jpg";

const theme = deepMerge(grommet, acme);

const AppBar = (props) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="teal"
    pad={{ left: "medium", right: "small", vertical: "small" }}
    elevation="medium"
    style={{ zIndex: "1" }}
    {...props}
  />
);

const App = () => {

  const [darkMode, setDarkMode] = useState(false);
  
  return (
    <Grommet full theme={theme} themeMode={darkMode ? "dark" : "light"}>
      <ResponsiveContext.Consumer>
        {(screenSize) => (
          <Box background={darkMode ? "dark-bg" : "light-bg"}>
            <AppBar>
              <Heading level="3" margin="none">
              <Avatar src={rumiavatarheader}/> 
              </Heading>
              <Button icon={darkMode ? <Moon/> : <Sun />} 
              onClick={() => {
                if (darkMode === false) {
                  setDarkMode(true)
                } else {
                  setDarkMode(false)
                }
              }}/>
            </AppBar>
            <Page kind="narrow" alignSelf="center">
              <PageContent>
                <Box direction="row-responsive" 
                pad={screenSize === "xsmall" ? "small" : "xlarge"}
                 justify="center">
                  <Grid columns={{ count: 1, size: "auto" }}>
                    <Box
                      style={{ zIndex: "1" }}
                      justify="center"
                      height="small"
                      width="medium"
                      round="xlarge"
                      background={{ color: "#ECECEC", opacity: "strong" }}
                    >
                      <Image src={rumiavatarhello} fit="cover" />
                    </Box>
                    <Box justify="center">
                      <Heading level="2" size="medium">
                        Hi, I'm Rumi.
                      </Heading>
                      <Text
                        size={screenSize === "xsmall" ? "small" : "medium"}
                        wordBreak="keep-all"
                      >
                        I'm an IT professional / Software Developer based in
                        Tokyo who is passionate about designing and building
                        with the latest technologies.
                      </Text>
                    </Box>
                  </Grid>
                </Box>
                <Box pad="xlarge" align="center">
                  <Grid
                    columns={
                      screenSize === "xsmall"
                        ? { count: 1, size: "auto" }
                        : { count: 2, size: "auto" }
                    }
                  >
                    <Box
                      height={screenSize === "xsmall" ? "small" : "small"}
                      width={screenSize === "xsmall" ? "small" : "small"}
                      align="center"
                      justify="center"
                    >
                      <Image
                        src={bitsnbytes}
                        fit={screenSize === "xsmall" ? "contain" : "cover"}
                      />
                    </Box>
                    <Box>
                      <Heading level="1" size="small">
                        About Me
                      </Heading>
                      <Paragraph>
                        <Text
                          size={screenSize === "xsmall" ? "small" : "medium"}
                          wordBreak="keep-all"
                        >
                          I am a full-stack software developer who specializes
                          in React, Typescript and Python. With proficiency in
                          AWS serverless infrastructure, I build and design apps
                          from scratch.
                        </Text>
                      </Paragraph>
                    </Box>
                  </Grid>
                </Box>
                <Box justify="center" pad="medium">
                  <Heading level="1" size="small">
                    Work Experience
                  </Heading>
                  <Accordion multiple>
                    <AccordionPanel label="DC Security Specialist @ a Leading Cloud Service Provider">
                      <Box overflow="auto" height="auto">
                        <Box height="auto" flex={false}>
                          <Text
                            size={screenSize === "xsmall" ? "small" : "medium"}
                          >
                            Interestingly, after my time as an Assistant IT
                            Manager at a hotel, I started my career in Security
                            not for the reasons of being in this industry but
                            for programming. My role's actual task is managing
                            build projects involving installation of security
                            system such as Lenel and CCTVs. But on the side, I
                            am part of a small team that develops applications
                            for automation of most of our team's manual tasks.
                            This role kicked off my career in programming.
                          </Text>
                        </Box>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel label="Assistant Manager of Technology @ a 5-star Luxury Hotel">
                      <Box overflow="auto" height="auto">
                        <Box height="auto" flex={false}>
                          <Text
                            size={screenSize === "xsmall" ? "small" : "medium"}
                          >
                            I then moved over to the hospitality industry to
                            become an in-house IT personnel. I was involved in
                            the management of the overall IT operations in a
                            hotel. Server management, network infrastructure
                            maintenance and management, and incident report
                            management are some of the major highlights of this
                            role. Part of my role is to help manage the in-room
                            technology that involves extra low voltage devices.
                            Lastly, I provided general IT support to our back of
                            house users and technical assistance to our guests.
                          </Text>
                        </Box>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel label="Data Center Engineer @ a Leading Cloud Service Provider">
                      <Box overflow="auto" height="auto">
                        <Box height="auto" flex={false}>
                          <Text
                            size={screenSize === "xsmall" ? "small" : "medium"}
                          >
                            Shortly after my IT consultant role, I was then
                            involved in 2 of the largest data center
                            institutions here in Tokyo as a contractual Data
                            Center Engineer. It was interesting to experience
                            the difference between these 2 data centers, given
                            that they are both of the same tier level
                            facility-wise. In the span of one year, I have
                            developed my hardware troubleshooting skills
                            further, focusing mostly on servers, switches and
                            storage devices. Also I have developed a more ITIL
                            related approach to processes, adapting heavily the
                            change management process. I also had the chance to
                            partake in new data center build out for both of the
                            companies.
                          </Text>
                        </Box>
                      </Box>
                    </AccordionPanel>
                    <AccordionPanel label="IT Consultant @ an Outsource IT Consulting Company">
                      <Box overflow="auto" height="auto">
                        <Box height="auto" flex={false}>
                          <Text
                            size={screenSize === "xsmall" ? "small" : "medium"}
                          >
                            This role kicked off my career as an IT
                            professional. During this 3-year post, I was exposed
                            to different natures of business and their IT
                            requirements. I provided support to a wide range of
                            clients - from established fintech companies to
                            marketing and advertising firms. I was able to hone
                            my skills in desktop and application support, user
                            PC and telephony setup and all other general IT
                            equipment support. I was also given the chance to
                            take on projects such as virtual migration and
                            network infrastructure revamp among other things.
                          </Text>
                        </Box>
                      </Box>
                    </AccordionPanel>
                  </Accordion>
                </Box>
                <Box pad={screenSize === "xsmall" ? "small" : "xlarge"} justify="center">
                  <Heading level="1" size="small">
                    Skills
                  </Heading>
                  {screenSize === "xsmall" ? (
                    <Grid columns={{ count: 1, size: "auto" }}>
                      <Box
                        justify="center"
                        height="small"
                        width="medium"
                        round="xlarge"
                        background={{ color: "#ECECEC", opacity: "strong" }}
                        gap="small"
                      >
                        <Image src={rumiavatarheart} fit="contain" />
                      </Box>
                      <Box justify="center">
                        <Paragraph>
                          <Text size="small">
                            Full stack Web Development | React Typescript,
                            Python{" "}
                          </Text>
                          <br />
                          <Text size="small" color="brand">
                            Windows | Linux Server Management
                          </Text>
                          <br />
                          <Text size="small">
                            Domain Controller Management and Tools
                          </Text>
                          <br />
                          <Text size="small" color="brand">
                            Azure | AWS Administration
                          </Text>
                          <br />
                          <Text size="small">
                            VMWare | Hyper-V | Citrix Setup and Application
                          </Text>
                          <br />
                          <Text size="small" color="brand">
                            Advanced Networking Design, Set-up and
                            Troubleshooting
                          </Text>
                          <br />
                          <Text size="small">
                            Trilingual: Native English and Filipino,
                            Conversational Japanese
                          </Text>
                          <br />
                        </Paragraph>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid columns={{ count: 2, size: "auto" }}>
                      <Box
                        justify="center"
                        height="medium"
                        width="small"
                        round="xlarge"
                        background={{ color: "#ECECEC", opacity: "strong" }}
                        gap="small"
                      >
                        <Image src={rumiavatarheart} fit="cover" />
                      </Box>
                      <Box justify="center">
                        <Paragraph>
                          <Text size="medium">
                            Full stack Web Development | React Typescript,
                            Python{" "}
                          </Text>
                          <br />
                          <Text size="medium" color="teal">
                            Windows | Linux Server Management
                          </Text>
                          <br />
                          <Text size="medium">
                            Domain Controller Management and Tools
                          </Text>
                          <br />
                          <Text size="medium" color="teal">
                            Azure | AWS Administration
                          </Text>
                          <br />
                          <Text size="medium">
                            VMWare | Hyper-V | Citrix Setup and Application
                          </Text>
                          <br />
                          <Text size="medium" color="teal">
                            Advanced Networking Design, Set-up and
                            Troubleshooting
                          </Text>
                          <br />
                          <Text size="medium">
                            Trilingual: Native English and Filipino,
                            Conversational Japanese
                          </Text>
                          <br />
                        </Paragraph>
                      </Box>
                    </Grid>
                  )}
                </Box>
                <Box pad="xlarge" justify="center">
                  <Grid columns={{ count: 2, size: "auto" }}>
                    <Box justify="center">
                      <Heading level="2" size="medium">
                        Let's talk
                      </Heading>
                      <Paragraph>I'm always up for a challenge!</Paragraph>
                    </Box>
                    <Box
                      justify="center"
                      height="small"
                      width="small"
                      round="xlarge"
                      background={{ color: "#ECECEC", opacity: "strong" }}
                      gap="small"
                    >
                      <Image src={rumiavatarcontact} fit="cover" />
                    </Box>
                    <Box direction="row" gap="small">
                      <Anchor hoverIndicator><Tip content="rumiko@kloudcore.com"><Image src={darkMode ? emaildark : emaillight } width="30px" /></Tip></Anchor>
                      <Anchor hoverIndicator><Tip content="+81-701-511-0519"><Image src={darkMode? phonedark: phonelight} width="30px" /></Tip></Anchor>
                    </Box>
                  </Grid>
                </Box>
              </PageContent>
            </Page>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};

export default App;
